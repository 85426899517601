@import "../../../../../../css/brandColors.scss";

.packageItems {
  display: inline-flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.nextStepBtn {
  margin-left: auto;

  @media (max-width: 991px) {
    width: 100% !important;
    margin-bottom: 32px;
  }
}

.fileUploader {
  margin-top: 16px;
}

.listFooter {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 24px;

  @media (min-width: 600px) {
    margin-top: 24px;
  }
}

.addNewBtn {
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #4ab6bd;
  stroke: #4ab6bd;
  margin-bottom: 40px;
}

.addAddressManuallyIcon {
  margin-right: 7px;
}

.packageItem {
  &:first-child {
    margin-right: 36px !important;
    display: none;
  }
}

.messageProhibitedItems {
  margin-top: 20px;
}

.messageProhibitedItemsLink {
  color: #a8b6d2;
}
