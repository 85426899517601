@import "../../css/brandColors.scss";
@import "../../css/breakpoints.scss";
@import "./variablesAppAddressBookEditor";

.FormAppAddressBookEditor {
  &Top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: $gap-form;

    @include breakpoint("mobile") {
      flex-direction: column;
      align-items: normal;
      justify-content: start;
    }

    &ReminderText {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      color: #00000070;
    }

    &CompanyToggle {
      margin-left: auto;

      @include breakpoint("mobile") {
        margin-left: 0;
        margin-right: auto;
        order: -1;
      }
    }
  }

  &Form {
    display: flex;
    flex-wrap: wrap;
    gap: $gap-form;
    margin-top: 24px;

    &SectionTitle {
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      color: #0000003d;
    }

    &Field {
      flex: 1 1 0;
      min-width: calc(50% - #{$gap-form} / 2);
      max-width: calc(50% - #{$gap-form} / 2);
      width: auto;

      @include breakpoint("mobile") {
        width: 100%;
        min-width: initial;
        max-width: initial;
        flex: none;
      }
    }

    &Company {
      display: flex;
      flex-wrap: wrap;
      gap: $gap-form;
      width: 100%;
    }

    &Control {
      width: 100%;
      margin-top: 32px - $gap-form;
    }
  }
}
