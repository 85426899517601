@import "../../css/brandColors.scss";
@import "./_Parcels.variables.scss";

.StyledEnvelopeSizeSelect {
  background-color: map-get($brandColors, "lightGray-100") !important;
}

.inputs {
  display: flex;
  flex-direction: row;
  gap: 20px 6px;
  width: 100%;

  // flex-direction: column;
  // @media (min-width: $media-min-width2) {
  //   flex-direction: row;
  // }
}

.removeBtn {
  position: absolute;
  right: 0px;
  top: 0px;
  flex-shrink: 0;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  color: #7f90b1 !important;
  margin-left: 0;
  margin-top: 0px;
}
