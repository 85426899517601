@import "../../../../../css/breakpoints.scss";
@import "../../../../../css/brandColors.scss";

.input {
  min-width: 300px;

  @media (max-width: #{map-get($breakpoints, md)}px) {
    min-width: auto;
    width: 100%;
  }
}

.pencilIcon {
  fill: map-get($brandColors, lightBlueColor) !important;
}
