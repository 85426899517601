@import "../../css/brandColors.scss";
@import "../../css/breakpoints.scss";

$common-gap: 16px;

.CardAddressBook {
  display: flex;
  flex-direction: column;
  gap: $common-gap;
  flex: 1 1 0;
  min-width: calc(50% - var(--gap-list-address-book, 24px) / 2);
  max-width: calc(50% - var(--gap-list-address-book, 24px) / 2);

  @media (max-width: 1100px) {
    width: 100%;
    min-width: initial;
    max-width: initial;
    flex: none;
  }

  &Content {
    display: flex;
    justify-content: space-between;
    gap: $common-gap;
    flex-direction: column-reverse;

    @media (min-width: 400px) {
      flex-direction: row;
    }

    &Left {
      display: flex;
      flex-direction: column;
      gap: $common-gap;

      &Title {
        font-size: 18px;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow-wrap: break-word;
        hyphens: auto;
      }

      &Contact {
        display: flex;
        flex-direction: column;
        gap: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;

        overflow: hidden;

        > * {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow-wrap: break-word;
          hyphens: auto;
        }
      }
    }

    &Right {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      gap: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      color: #00000070;
      text-align: right;
    }
  }

  &Footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: $common-gap;

    &Btn {
      background-color: map-get($brandColors, "lightestBlue") !important;
      color: map-get($brandColors, "mainBlue") !important;
      border-radius: 8px !important;
      padding: 8px 32px !important;
      font-size: 12px !important;
      font-weight: 600 !important;
      line-height: 16px !important;
      letter-spacing: 0px !important;
      text-decoration: none;
    }
  }
}
