.MenuLayoutWithLeftMenu {
  min-width: 275px;
  max-width: 275px;
  height: fit-content;

  &FullName {
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0px;
    border-bottom: 1px solid #e5e9f3;
    padding-top: 8px;
    padding-bottom: 16px;
  }

  &Nav {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    margin-top: 32px;
    margin-bottom: 8px;

    &Link {
      cursor: pointer;
      text-decoration: none;
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
    }

    &SingOut {
      color: #0000008f;
      cursor: pointer;
    }
  }
}
