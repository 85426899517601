@import "../../../../../../../css/brandColors.scss";

:root {
  --commodity-code-search-modal-horizontal-padding: 8px;
  --commodity-code-search-modal-cell-horizontal-padding: 8px;

  @media (min-width: 400px) {
    --commodity-code-search-modal-horizontal-padding: 24px;
    --commodity-code-search-modal-cell-horizontal-padding: 16px;
  }
}

.modal {
  width: 100%;
  max-width: 465px;
  padding-left: var(
    --commodity-code-search-modal-horizontal-padding
  ) !important;
  padding-right: var(
    --commodity-code-search-modal-horizontal-padding
  ) !important;
}

.title {
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 24px;
}

.indentBetweenFields {
  margin-top: 24px;
}

.findBtn {
  font-weight: 600;
  background-color: #284492;
  padding: 16px 35px;
  border-radius: 16px;
  font-size: 16px;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 100%;
  margin-top: 16px;
}

.searchHint {
  margin-top: 16px;
  text-align: center;
  color: map-get($brandColors, "mainBlue");
}

.searchByCategoryText {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 16px;
}

.messageSelectHsCode {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #00000080;
  margin-bottom: 32px;
}

$min-height-hs-code-list: 115px;

.hsCodeList {
  position: relative;
  min-height: $min-height-hs-code-list;
  margin-top: 16px;
}

$cell-vertical-padding: 8px;

.tableHsCodeList {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 8px;

  th {
    padding: $cell-vertical-padding
      var(--commodity-code-search-modal-cell-horizontal-padding) 0
      var(--commodity-code-search-modal-cell-horizontal-padding);
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
  }

  td {
    background-color: map-get($brandColors, "lightGray-100");
    padding: $cell-vertical-padding
      var(--commodity-code-search-modal-cell-horizontal-padding);

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  tbody {
    tr {
      cursor: pointer;

      td {
        transition: background-color 0.2s ease-in;
      }

      &:hover {
        td {
          background-color: #e5e9ef;
        }
      }
    }
  }
}

.hrCodesNotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $min-height-hs-code-list;
  font-weight: 600;
  font-size: 16px;
  color: #ffae3b;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
