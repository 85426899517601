$breakpoints: (
  xs: 0,
  sm: 375,
  md: 600,
  lg: 1248,
  xl: 1920 // xs: 0, // TODO: поменять на такие, они используются в tailwinds
  // sm: 640,
  // md: 768,
  // lg: 1024,
  // xl: 1280,,,
);

:export {
  @each $key, $value in $breakpoints {
    #{unquote($key)}: $value;
  }
}

$mediaBreakpoints: (
  "mobile": "(max-width: #{map-get($breakpoints, md)}px)",
  "tablet": "(max-width: #{map-get($breakpoints, lg)}px)",
  "desktop": "(max-width: #{map-get($breakpoints, xl)}px)",
  "desktop2k": "(min-width: #{map-get($breakpoints, lg)}px)",
);

@mixin breakpoint($key) {
  @media #{map-get($mediaBreakpoints, $key)} {
    @content;
  }
}
