@import "../css/breakpoints.scss";

.root {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 900px;
  margin-top: 67px;

  @include breakpoint("mobile") {
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 32px;
    padding: 0 30px;
  }
}

.card {
  display: flex;
  border-radius: 99999px;
  background-color: #f3f4f9;
  height: 40px;

  @include breakpoint("mobile") {
    height: 30px;
  }
}

.unity {
  img {
    width: 75px;
    height: 28px;

    @include breakpoint("mobile") {
      width: 53px;
      height: 21px;
    }
  }
}
