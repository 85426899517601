@import "../../../../../../css//breakpoints.scss";

.removeBtn {
  position: absolute;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  color: #7f90b1 !important;
  left: 0px;
  top: 0px;
  margin-left: 0;
  margin-top: 0px;
  flex-shrink: 0;

  @media (min-width: #{map-get($breakpoints, lg)}px) {
    margin-top: 12px;
    position: relative;
  }
}
