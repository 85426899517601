@import "../../css/breakpoints.scss";

// .active {
//   display: none !important;
//   color: red !important;

//   * {
//     color: red !important;
//   }
// }

.rightPanelWrap {
  width: 100%;

  @include breakpoint("mobile") {
    order: -1;
  }
}

.rightPanel {
  position: sticky !important;
  top: 96px;
}
