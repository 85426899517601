.tab {
  padding-bottom: 60px;
}

.notification {
  margin-bottom: 16px;
}

.formCheckboxWrapper {
  max-width: 405px;
}

.AccordionItemsWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
