@import "variables.scss";
@import "breakpoints.scss";

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
}

.fade-up {
  transition: all 500ms ease;
  opacity: 0.01;
  transform: translateY(0);
}

.fade-up-enter {
  transform: translateY(10px);
  opacity: 0.01;
}
.fade-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 500ms ease;
}
.fade-up-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-up-exit-active {
  opacity: 0.01;
  transform: translateY(10px);
  transition: all 500ms ease;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 22px;
  background: #e5e9f3;
}
::-webkit-scrollbar-thumb {
  background: #a8b6d2;
  border-radius: 10px;
}

// === common-card ===
$padding-common-card: 20px;
$padding-bottom-common-card: $padding-common-card +
  $field-hint-error-message-min-height;

.commonCard {
  background: #ffffff;
  border-radius: $border-radius-lg;
  padding: $padding-common-card $padding-common-card $padding-bottom-common-card
    $padding-common-card;

  @include breakpoint("mobile") {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.commonCardThinBottom {
  @extend .commonCard;
  padding-bottom: $padding-bottom-common-card -
    $field-hint-error-message-min-height;
}
// === common-card END ===

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
