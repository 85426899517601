.submitBtn {
  margin-left: auto;
}

.formRadioGroup {
  margin: 24px 0 10px 0;
}

.formTypeRadioGroup {
  margin-bottom: 12px;
}

.fieldLabel {
  margin-top: 24px;
}

.input {
  margin-bottom: 20px !important;
}

.switch {
  margin-bottom: 20px;
}

.card {
  padding: 24px 40px;
  margin-top: 10px;

  @media (max-width: 991px) {
    padding: 20px;
  }
}
