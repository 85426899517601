@import "../../../../../../../../css/breakpoints.scss";

.moneyInput {
  display: flex !important;
  flex-direction: column;
  width: 100px !important;

  @include breakpoint("mobile") {
    width: auto !important;
  }
}
