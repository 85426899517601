@import "./GetBestPricePopup.variables.scss";

// @font-face {
//   font-family: Righteous-Regular;
//   src: url(../../assets/fonts-mont/Righteous-Regular.woff2);
// }

$side-padding-container: 8px;

.modal {
  width: 95%;
  padding: 24px 24px - $side-padding-container !important;
  padding-top: 40px !important;

  @media (min-width: 450px) {
    width: 80%;
  }

  @media (min-width: $media-min-width) {
    max-width: 800px;
    width: 100%;
    padding: 40px - $side-padding-container !important;
  }
}

.isSentModal {
  max-width: 360px !important;
}

.container {
  display: flex;
  flex-direction: column-reverse;
  gap: 24px 0px;
  padding: 0 $side-padding-container;

  @media (min-width: $media-min-width) {
    flex-direction: row;
    padding-right: initial;
  }
}

.bannerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  flex: 1 1 0;

  @media (min-width: $media-min-width) {
    gap: 33px;
  }
}

.fromToSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f4f9;
  order: 1;
  // padding: 7px;
  width: 231px;
  border-radius: 38px;
  margin-top: 4px;

  @media (min-width: $media-min-width) {
    order: 0;
    width: 279px;
    // padding: 9px;
    margin-top: initial;
    margin-right: 60px;
  }
}

.fromToSectionFlag {
  width: 46px;
  height: 46px;

  @media (min-width: $media-min-width) {
    width: auto;
    height: auto;
  }
}

.fromToText {
  display: flex;
  align-items: center;
  color: #284492;
  leading-trim: both;
  text-edge: cap;
  font-family: Righteous-Regular;
  font-style: normal;
  font-size: 11.425px;
  font-weight: 400;
  line-height: 140%; /* 15.994px */
  text-transform: uppercase;
  padding: 1px 0;

  @media (min-width: $media-min-width) {
    font-size: 13.778px;
    font-weight: 400;
    line-height: 140%; /* 19.289px */
  }

  svg {
    width: 33px;
    margin: 0 5px;

    @media (min-width: $media-min-width) {
      width: auto;
      margin: 0 8px;
    }
  }
}

.banner {
  display: none;
  width: 144px;
  margin: auto;

  @media (min-width: $media-min-width) {
    display: block;
    width: auto;
    margin: initial;
  }
}

.BannerMobile {
  width: 144px;
  margin: auto;
  @media (min-width: $media-min-width) {
    display: none;
  }
}
