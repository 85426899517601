@import "../../../../css/brandColors.scss";
@import "../../../../css/mixins.scss";
@import "../../../../css/breakpoints.scss";

$breakpoint-to-mobile: 1080px;

@mixin banner-title {
  font-family: NotoSansHebrew-SemiBold;
  // font-weight: 700; // ! В Chrome толщина не влияет на шрифт, а в Safari влияет
  font-size: adaptiv-size(48, 24);
  line-height: adaptiv-size(56, 31);
  font-style: normal;
}

@mixin banner-subtitle {
  font-weight: 400;
  font-size: adaptiv-size(16, 14);
  line-height: adaptiv-size(20.8, 18.2);
}

.title {
  font-family: NotoSansHebrew-SemiBold;
  // font-weight: 700; // ! В Chrome толщина не влияет на шрифт, а в Safari влияет
  font-size: adaptiv-size(48, 26);
  line-height: adaptiv-size(68, 33.8);
  text-align: center;
  color: map-get($brandColors, "black");
  margin-top: 64px;
  padding: 0 40px 0 40px;

  @include breakpoint("mobile") {
    margin-top: 32px;
  }
}

.subTitle {
  font-weight: 400;
  font-size: adaptiv-size(18, 14);
  line-height: 150%;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 16px;
  margin-bottom: 48px;
  padding: 0 40px 0 40px;
  color: #112356;

  @media (min-width: $breakpoint-to-mobile) {
    margin-bottom: 18px;
  }
}

$gap-banner-centering: 48;
$gap-banner-centering-mobile: 20;

.bannerCentering {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: adaptiv-size($gap-banner-centering, $gap-banner-centering-mobile);
  margin: 0 20px;

  > div {
    > img {
      max-width: 1328px;

      @media (min-width: $breakpoint-to-mobile) {
        width: 100%;
      }
    }
  }
}

.contentBannerWrapper {
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: $breakpoint-to-mobile) {
    position: relative;
    max-width: 1328px;
    display: flex;
    background: transparent;
    border-radius: initial;
  }

  img {
    margin-left: auto;
    margin-right: auto;
  }
}

$left-banner-mobile: 6%;
$left-banner-desktop: 6%;

.contentBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
  margin-bottom: 48px;
  padding-top: 32px;
  padding-left: 24px;
  padding-right: 24px;

  @media (min-width: $breakpoint-to-mobile) {
    position: absolute;
    text-align: initial;
    margin-top: initial;
    margin-left: initial;
    margin-bottom: initial;
    padding: initial;
  }

  > h2 {
    @include banner-title;
    color: map-get($brandColors, "black");
    margin-bottom: adaptiv-size(24, 12);
    // color: red;
  }

  > p {
    @include banner-subtitle;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.6;
    // color: red;
  }
}

.banner1-wrapper {
  background: #f3f4f9;

  @media (min-width: $breakpoint-to-mobile) {
    background: transparent;
  }

  > img {
    width: 49%;
  }
}

.content-banner1 {
  @media (min-width: $breakpoint-to-mobile) {
    right: 50%;
    left: 2%;
  }
}

.banner2-wrapper {
  background: #ffe5db;

  @media (min-width: $breakpoint-to-mobile) {
    background: transparent;
  }

  > img {
    width: 87%;
    margin-bottom: 6%;
    filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.15));

    @media (min-width: $breakpoint-to-mobile) {
      margin-bottom: initial;
      filter: initial;
    }
  }
}

.content-banner2 {
  @media (min-width: $breakpoint-to-mobile) {
    right: 4%;
    max-width: 48%;
  }
}

.banner3-wrapper {
  background: #dbe5ff;
  margin-top: 0px;

  @media (min-width: $breakpoint-to-mobile) {
    background: transparent;
    margin-top: -28px;
  }

  > img {
    width: 75%;
  }
}

.content-banner3 {
  @media (min-width: $breakpoint-to-mobile) {
    top: 3%;
    right: 4%;
    max-width: 40%;
  }
}

.banner4-wrapper {
  background: #f8eed2;

  @media (min-width: $breakpoint-to-mobile) {
    background: transparent;
  }

  > img {
    width: 87%;
    margin-bottom: 6%;

    @media (min-width: $breakpoint-to-mobile) {
      margin-bottom: initial;
    }
  }
}

.content-banner4 {
  @media (min-width: $breakpoint-to-mobile) {
    top: -11%;
    right: 50%;
    left: 11%;
  }
}

.agitation-homepage {
  &__banners {
    &__video {
      background: map-get($brandColors, "black");
      display: flex;
      padding: adaptiv-size(64, 48) adaptiv-size(54, 20);
      margin-top: adaptiv-size(72, 20);

      &__content {
        margin: auto;
        max-width: 1328px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px 60px;

        @media (min-width: 1000px) {
          flex-direction: row;
        }

        &__text-wrapper {
          &__title {
            @include banner-title;
            color: #ffffff;
            margin-bottom: adaptiv-size(53, 32);
          }

          &__subtitle {
            @include banner-subtitle;
            display: flex;
            align-items: center;
            gap: 10px;
            color: #ffffff;
            margin-top: 24px;

            &:first-of-type {
              margin-top: 0px;
            }

            &__icon {
              width: 18px;
              height: 18px;
            }
          }
        }

        &__video {
          width: 100%;
          max-width: 654px;
          border-radius: adaptiv-size(32, 24);
          overflow: hidden;

          & > * {
            height: 0px;
            width: 100%;
            padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
            position: relative;
            border-radius: adaptiv-size(
              32,
              24
            ); // TODO: не смог проверить. Нужно для Safari на телефоне

            iframe,
            object,
            embed {
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              position: absolute;
              border-width: 0;
              outline-width: 0;
              border-radius: adaptiv-size(
                32,
                24
              ); // TODO: не смог проверить. Нужно для Safari на телефоне
            }
          }
        }
      }
    }
  }
}

.content-banner6 {
  position: absolute;
  text-align: center !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 100%;
  margin: auto;

  > h2,
  > p {
    color: #ffffff;
    // max-width: 381px;
    margin-left: auto;
    margin-right: auto;
  }
}

.contentBanner6SubTitle {
  font-size: adaptiv-size(24, 18);
  font-weight: 800;
}

.banner6-wrapper {
  position: relative;
  margin-top: adaptiv-size(96, 40);
  margin-bottom: adaptiv-size(56, 40);
  max-width: 848px;
}

.getPromoBtn {
  display: flex;
  align-items: center;
  padding: 14px 40px;
  gap: 11px;
  height: 54px;
  background: #ffffff;
  border-radius: 16px;
  margin: 32px auto 0 auto;
  color: map-get($brandColors, "black") !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  width: fit-content;
  text-decoration: none;

  > img {
    width: 26px;
    height: 26px;
  }
}

.linkBannerBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 54px;
  padding: 14px 40px;
  gap: 10px;
  border-radius: 16px;
  background: #284492;
  color: #fff !important;
  margin: 32px auto 0 auto;
  width: fit-content;
  text-decoration: none;

  @media (min-width: $breakpoint-to-mobile) {
    margin: 40px 0 0 0;
  }
}
