@import "../../../../../../../../css/breakpoints.scss";
@import "../../../../../../../../css/brandColors.scss";

.radioGroup {
  margin-left: 24px;

  @include breakpoint("tablet") {
    margin-left: 12px;
    margin-top: 16px;
    width: 100%;
  }
}

.submitBtn {
  display: flex;
  align-self: flex-end;

  @include breakpoint("tablet") {
    margin-top: 32px;
    width: 100% !important;
  }
}

.local-time-from-where-to-pick-up {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.2;
  color: map-get($brandColors, mainBlue);

  &__time {
    font-weight: 600;
  }

  &__gmt {
    color: map-get($brandColors, lightBlueColor);
  }
}

.MessagePickupToday {
  color: map-get($brandColors, mainBlue);
  text-align: center;
  line-height: 1.5;
  margin: 16px 0;

  &Time {
    font-weight: 600;
  }
}
