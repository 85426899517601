@import "../../../../../../../../css/brandColors.scss";

$min-width-modal: 50vw;
$max-width-modal: 600px;
$padding-modal: 30px;

.modalInsurance {
  overflow: hidden !important;
  padding: $padding-modal !important;
  padding-top: 40px !important;
  min-width: $min-width-modal !important;
  max-width: $max-width-modal !important;
  width: 100% !important;
}

.contentModalInsurance {
  display: flex;
  flex-direction: column;
  gap: 20px;
  line-height: 1.5;
  padding-right: 8px;
  padding-bottom: 80px;
}

.fontWeightBold {
  font-weight: bold;
}

.listOffset {
  margin-left: 20px;
}

.link {
  // color: #284492;
  color: #{map-get($brandColors, mainBlue)};
}

.control {
  position: absolute;
  bottom: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 10px;
  min-width: calc(#{$min-width-modal} - #{$padding-modal * 2});
  max-width: calc(#{$max-width-modal - $padding-modal * 2});
  width: calc(100% - #{$padding-modal * 2});
}
