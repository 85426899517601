.MetricSystemBtns {
  display: inline-flex;
  border-radius: 99999px;
  min-width: 207px;
  justify-content: center;
  overflow: hidden;
  border: 1px solid rgba(127, 144, 177, 0.3);

  &Btn {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // gap: 8px;
    padding: 4px 28px;
    width: 50%;
  }

  &BtnLabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
  }

  // &BtnIcon {
  //   color: #4AB6BD !important;
  // }

  &BtnActive {
    background: rgba(127, 144, 177, 0.3);
  }
}
