.fade-up {
  transition: all 500ms ease;
  opacity: 0.01;
  transform: translateY(0);
}

.fade-up-enter {
  transform: translateY(10px);
  opacity: 0.01;
}
.fade-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 500ms ease;
}
.fade-up-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-up-exit-active {
  opacity: 0.01;
  transform: translateY(10px);
  transition: all 500ms ease;
}

