@import "../../../../../css/breakpoints.scss";

.nextBtn {
  position: absolute !important;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 27px);
  transition: all 0.5 ease-in;
}

.counterBtn {
  // margin-left: 12px;
}

.card {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  height: 56px;
  position: relative;
  // min-width: 360px;
  min-width: 230px;
  // max-width: 380px;
  justify-content: space-between;

  @include breakpoint("tablet") {
    justify-content: space-between;
    max-width: 100%;
    flex: 1 1 auto;
    min-width: auto;
  }
}

.searchAddress {
  width: 100% !important;
}

.sizeHint {
  position: absolute;
  top: 27px;
  transform: translateX(-300px);
  z-index: 10;
  left: 360px;
  min-width: 332px;

  @include breakpoint("tablet") {
    left: 310px;
  }

  @include breakpoint("mobile") {
    display: none;
  }
}

.select {
  max-width: 130px;
}

.searchInput {
  width: 100%;

  @include breakpoint("tablet") {
    height: 40px;

    & input {
      min-height: 40px;
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: 120px;
    width: 100px;
    height: 100%;
    top: 0;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 73.55%,
        #ffffff 102.17%
      ),
      #28449200;
  }
}

.CountrySelect {
  flex-shrink: 0;
  min-width: 207px;
  width: 100%;

  & > div {
    margin-top: -10px !important;
  }

  & > button {
    padding: 18px !important;
  }
}

.TransitionEnter,
.TransitionAppear {
  opacity: 0;
}

.TransitionEnterActive,
.TransitionAppearActive {
  opacity: 1;
  transition: opacity 300ms;
}

.TransitionExit {
  opacity: 1;
}

.TransitionExitActive {
  opacity: 0;
  transition: opacity 300ms;
}
