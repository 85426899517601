.ContactForm {
  &SectionTitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #000000;
    margin-top: 12px;
    margin-bottom: 16px;
  }
}

.input {
  min-height: 60px;
}

.privateCompanyToggle {
  margin-top: 4px;
  margin-bottom: 24px;
}

.addAddressManually {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #4ab6bd;
  stroke: #4ab6bd;
  margin-bottom: 40px;
}

.addAddressManuallyIcon {
  margin-right: 7px;
}

.SearchAddress {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  & > *:first-child {
    width: 100%;
  }
}
