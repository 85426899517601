$header-height-with-margin: 72px;
$media-min-width-menu-number: 600;
$media-min-width-menu: $media-min-width-menu-number + px;
$gap-common: 24px - 8px;

:export {
  headerHeightWithMargin: $header-height-with-margin;
  mediaMinWidthMenuHeaderNumber: $media-min-width-menu-number;
  mediaMinWidthMenuHeader: $media-min-width-menu;
  gapCommonHeader: $gap-common;
}
