@import "../../../css/brandColors.scss";

.otpGroup {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otpInput {
  width: 100%;
  height: 48px;
  border: none;
  border-bottom: 1px solid #a8b6d2;
  padding: 0;
  text-align: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #{map-get($brandColors, mainBlue)};
  outline: none;

  &:focus {
    border-color: #{map-get($brandColors, mainBlue)};
  }
}
