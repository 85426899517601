@import "../../css/brandColors.scss";
@import "./variablesAppAddressBookEditor";

.ControlFormAppAddressBookEditor {
  display: flex;
  gap: $gap-form;
  flex-wrap: wrap;

  &Btn {
    padding: 8px 40px !important;
    border-radius: 8px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 26px !important;
    letter-spacing: 0px !important;
    text-transform: initial !important;
    border: none !important;

    &Cancel {
      background-color: map-get($brandColors, "lightestBlue");
      color: map-get($brandColors, "mainBlue");
    }

    &Submit {
      background-color: map-get($brandColors, "mainBlue") !important;
      color: #ffffff !important;
    }
  }
}
