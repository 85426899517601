$layout-width: 1440;

@function adaptiv-size($pc-size, $mob-size, $min-width: 375) {
  $add-size: $pc-size - $mob-size;
  $max-width: $layout-width - $min-width;

  //  @return calc(#{$mob-size + px} + #{$add-size} * ((100vw - #{$min-width + px}) / #{$max-width}));
  @return clamp(
    #{$mob-size + px},
    calc(
      #{$mob-size + px} + #{$add-size} *
        ((100vw - #{$min-width + px}) / #{$max-width})
    ),
    #{$pc-size + px}
  );
}
