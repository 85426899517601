.NotificationPrices {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(255, 171, 28, 0.34);
  background-image: linear-gradient(
    0deg,
    rgba(255, 171, 28, 0.12),
    rgba(255, 171, 28, 0.12)
  );
  // rgba(255, 171, 28, 0.34));
  padding: 10px 24px 10px 8px;
  border-radius: 8px;
  color: #000;
  margin-bottom: 24px;
}

.top {
  display: flex;
  align-items: center;
  gap: 12px;
}

.column {
  width: 4px;
  height: 48px;
  border-radius: 6px;
  background-color: rgba(255, 171, 28, 1);
  flex-shrink: 0;
}

.icon {
  flex-shrink: 0;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
}

.btn {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
}

.modal {
  width: 100% !important;
  padding: 60px 16px 40px 16px !important;

  @media (min-width: 600px) {
    width: 75% !important;
    max-width: 600px !important;
    padding: 60px 40px 40px 40px !important;
  }
}
