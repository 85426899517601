@import "../../../../css/breakpoints.scss";
@import "../../../../css/brandColors.scss";

.switch-btn-domestic {
  display: flex;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f3f4f9;
  border-radius: 9999px;
  // padding: 8px;
  padding: 6px;

  @include breakpoint("tablet") {
    margin-top: 24px;
  }

  &__btn {
    border-radius: 9999px;
    // padding: 12px 20px;
    padding: 8px 30px;

    &__active {
      // box-shadow: 0px 6px 12px 0px #28449240;
      background-color: map-get($brandColors, "mainBlue");
      color: #ffffff;
    }
  }
}
