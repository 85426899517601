.item-enter {
  transform: translateY(50px);
  opacity: 0.01;
}
.item-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 500ms ease;
}
.item-exit {
  opacity: 1;
  transform: translateY(0);
}
.item-exit-active {
  opacity: 0.01;
  transform: translateY(50px);
  transition: all 500ms ease;
}

li {
  list-style: none;
}
