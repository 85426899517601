.language {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  color: #284492;
}

.languageSelect {
  * {
    cursor: pointer !important;
  }

  button {
    padding: 0 !important;
    font-size: 14px;
    font-style: normal;
    color: #a8b6d2;

    > div {
      display: flex;
      align-items: center;
      gap: 6px;

      svg {
        color: #284492 !important;
      }
    }
  }
}
