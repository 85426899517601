.selectIcon {
  button {
    padding: 0;
    padding: 5px 8px 6px 4px;
    min-width: none;
    background: none;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 2px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    }
  }
}

.labelOffset {
  label {
    left: -36px;
  }
}
