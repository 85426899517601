@import "./GetBestPricePopup.variables.scss";

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.HappySmiley {
  width: 128px;
  height: 128px;
}

.title {
  color: var(--Black, #112356);
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: NotoSansHebrew-Bold;
  font-size: 32px;
  line-height: 1.25;
  font-style: normal;
  margin-top: 24px;

  @media (min-width: $media-min-width) {
    font-size: 36px;
    line-height: 40px; /* 111.111% */
  }
}

.subTitle {
  color: rgba(17, 35, 86, 0.71);
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: NotoSansHebrew-SemiBold;
  font-size: 16px;
  line-height: 31.5;
  font-style: normal;
  margin-top: 12px;

  @media (min-width: $media-min-width) {
    font-size: 20px;
    line-height: 32px; /* 160% */
  }
}
