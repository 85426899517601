$brandColors: (
  "mainBlue": #284492,
  "lightestBlue": #e5e9f3,
  "whiteColor": #fff,
  "lightGray": #e1e1e2,
  "lightGray-100": #f8f8fa,
  "gray": #aeabab,
  "error": #ff5c5c,
  "lightRed": #fea2a2,
  "lightBlueColor": #a8b6d2,
  "black": #112356,
  "grayWhite": #fdf6f6,
);

// TODO: цвет #00000070 - цвет нижней границы инпута вынести в отдельную переменную. Так как он используется не только в компоненте инпута, но в интерфейсе схожем со стилистикой инпута

:export {
  @each $key, $value in $brandColors {
    #{unquote($key)}: $value;
  }
}
