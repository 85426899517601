.ui-page-background {
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 0;
  transition: 1s;
  /* overflow: hidden; */
}

.ui-page-background svg path:nth-child(odd) {
  animation: fadeOdd 20s ease-in infinite;
}

.ui-page-background svg path:nth-child(even) {
  animation: fadeEven 20s ease-in infinite;
}

@keyframes fadeOdd {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    opacity: 0.2;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    opacity: 1;
  }
}

@keyframes fadeEven {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    opacity: 1;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    opacity: 0.2;
  }
}
