@import "../../../../css/brandColors.scss";
@import "../../../../css/mixins.scss";
@import "../../../../css/breakpoints.scss";

.top-home-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.top {
  display: flex;
  justify-content: center;
  margin-top: 64px;
  justify-content: space-between;

  @include breakpoint("mobile") {
    margin: 20px auto 0 auto;
  }

  @include breakpoint("tablet") {
    flex-direction: column;
    max-width: 382px;
    margin-left: auto;
    margin-right: auto;
  }
}

.topLeft {
  display: flex;
  flex-direction: column;

  @include breakpoint("tablet") {
    width: auto;
  }
}

.topTitle {
  font-family: NotoSansHebrew-Bold;
  font-style: normal;
  // font-weight: 700; // ! В Chrome толщина не влияет на шрифт, а в Safari влияет
  font-size: adaptiv-size(40, 30);
  line-height: adaptiv-size(48, 36);
  color: #{map-get($brandColors, "black")};

  @include breakpoint("tablet") {
    text-align: center;
    margin-top: 24px;
  }
}

.subTitle {
  font-family: NotoSansHebrew-SemiBold;
  font-style: normal;
  // font-weight: 700; // ! В Chrome толщина не влияет на шрифт, а в Safari влияет
  font-size: adaptiv-size(30, 18);
  line-height: adaptiv-size(40, 26);
  color: #{map-get($brandColors, "black")};
  margin-top: 4px;

  @include breakpoint("tablet") {
    text-align: center;
    margin-top: 12px;
  }
}

.topSubTitle {
  display: flex;
  gap: 23px;
  margin-top: adaptiv-size(32, 16);

  @include breakpoint("tablet") {
    display: none;
  }
}

.topLottie {
  flex: 1 1 auto;
  max-width: 355px;
  display: flex;

  @include breakpoint("tablet") {
    order: -1;
  }
}

.inputSection {
  position: relative;
  background: #f3f4f9;
  padding: 24px 24px 79px 24px;
  border-radius: 16px;
  margin-top: 20px;
  width: 100%;

  @include breakpoint("tablet") {
    padding: 24px 12px 62px 12px;
    margin-top: 16px;
    width: auto;
  }

  @include breakpoint("mobile") {
    width: 100%;
    max-width: 382px;
  }
}

.shadowToCitySelectionField {
  box-shadow: 0px 2px 1px rgba(17, 35, 86, 0.25);
}

.shadowToCitySelectionFieldError {
  box-shadow: 0px 2px 1px #ff5c5c;
}

.topItem {
  display: flex;
  align-items: center;
  gap: 16px;
}

.topItemText {
  > div {
    line-height: 1.5;
  }
}

.topImg {
  width: 56px;
  height: 56px;
}
