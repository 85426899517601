.top-price-card {
  display: flex;
  width: 75%;
  margin-left: auto;

  &--full-width {
    width: 100%;
  }

  &__item {
    display: flex;
    flex: 1 1 auto;

    &__separator {
      width: 1px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.08);
      margin: 0 4%;
      flex-shrink: 0;
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      align-items: center;
      justify-content: center;
      color: #7f90b1;
      font-size: 12px;
      text-align: center;

      &__icon {
        width: 24px;
        height: 24px;
        margin-bottom: 4px;
      }
    }
  }
}
