.rate-class-price-card {
  position: absolute;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  width: 196px;
  height: 35px;
  transform: rotate(-45deg);
  text-align: center;
  line-height: 35px;
  left: -55px;
  top: 25px;

  &__economy {
    background-color: #4ab6bd;
  }

  &__express {
    background-color: #112356;
  }

  &__optimal {
    background-color: #ff5c5c;
  }

  &__undefined {
    background-color: #e5e9f3;
  }
}
