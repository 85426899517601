@import "../../../../../../css/breakpoints.scss";

.firstMobileTab {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
}

.mobileNextBtnTabFirst {
  bottom: 30px;
  z-index: 0;

  @media (min-width: #{map-get($breakpoints, md)}px) {
    display: none !important;
  }
}

.mobileNextBtnTabSecond {
  margin: 32px auto 0 auto;
}

.backMobileBtn {
  margin-top: 24px;
  color: #000000 !important;
}
