$column-gap: 16px;
$min-width: 700px;

.StyledSelectWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  column-gap: $column-gap;
  width: 100%;

  & > * {
    flex-direction: column;
    display: flex;
    align-items: baseline;
    column-gap: $column-gap;
    width: 100%;

    @media (min-width: $min-width) {
      flex-direction: row;
      row-gap: initial;
    }
  }
}

.moneyInput {
  & input {
    // text-align: right;
  }
}

.whatAndHowMuch {
  display: flex;
  align-items: center;
  gap: 8px 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.hsCode {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 20px 20px;

  & > * {
    flex: 1 1 0;
    min-width: 150px;
  }
}

.CountrySelect {
  button {
    padding: 4px 0px;
  }
}

.wrapperRemoveBtn {
  position: absolute;
  right: -16px;
  top: -16px;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 99999px;
}

.removeBtn {
  color: #a8b6d2;
  cursor: pointer;
}

.inputItem {
  min-width: 160px;
  width: 100%;

  @media (min-width: $min-width) {
    width: 25%;
  }
}

.priorityWidth {
  width: 50%;
}

.Quantity {
  width: 100% !important;

  @media (min-width: $min-width) {
    width: 25% !important;
  }
}

.field25 {
  width: 100%;
}
