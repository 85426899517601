@import "../../css/variables.scss";
@import "../../css/breakpoints.scss";

.addNewAddressBtn {
  display: flex;
  align-items: center;
  background-color: #284492;
  color: #ffffff;
  padding: 11px 20px;
  border-radius: $border-radius-lg;
  stroke: #ffffff;
  text-decoration: none;

  &Label {
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0px;
    margin-left: 8px;

    @include breakpoint("tablet") {
      margin-left: 4px;
    }
  }
}
