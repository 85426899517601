@import "../../../../css/brandColors.scss";
@import "../../../../css/mixins.scss";

$breakpoint-to-mobile: 1080px;
$left-banner-desktop: 6%;

.content-banner5 {
  padding-top: 48px;

  @media (min-width: $breakpoint-to-mobile) {
    position: relative;
    align-items: center;
    margin-left: auto;
    max-width: 50%;
    width: 100%;
    padding-top: 6%;
    padding-bottom: 6%;
    padding-left: 4%;
  }
}

.banner5-wrapper {
  margin-top: adaptiv-size(64, 20);
  background: #f6f6f9;

  @media (min-width: $breakpoint-to-mobile) {
    border-radius: 32px;
  }
}

$height-stores-btn: 54px;

.actionBanner5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: map-get($brandColors, "black");

  @media (min-width: $breakpoint-to-mobile) {
    margin-top: 58px;
    flex-wrap: wrap;
  }
}

.storeBtnsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 24px;

  @media (min-width: $breakpoint-to-mobile) {
    justify-content: initial;
    gap: 24px;
    margin-top: 58px;
  }
}

.storeBtn {
  display: flex;
  align-items: center;
  padding: 11px 9px;
  gap: 8px;
  background: #ffffff;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: map-get($brandColors, "black");
  text-decoration: none;
  height: $height-stores-btn;

  @media (min-width: $breakpoint-to-mobile) {
    padding: 6px 16px;
    font-size: 12px;
    gap: 10px;
  }

  > * {
    cursor: pointer !important;
  }

  > img {
    width: 24px;
    height: 24px;

    @media (min-width: $breakpoint-to-mobile) {
      width: 32px;
      height: 32px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    text-align: left;

    > span {
      font-weight: 600;
      font-size: 18px;
      margin-top: 4px;

      @media (min-width: $breakpoint-to-mobile) {
        font-size: 22px;
      }
    }
  }
}

.qrCodeAppStores {
  > div {
    background: #ffffff;
    border-radius: 22px;
    height: 200px;
    width: 200px;
    padding: 16px;

    > img {
      width: 100%;
    }
  }
}

.img-banner5 {
  width: 100%;
  bottom: initial;

  @media (min-width: $breakpoint-to-mobile) {
    position: absolute;
    bottom: -12%;
    width: 48%;
    max-width: 632px;
  }
}

.img-banner5-wrapper {
  margin-left: auto;
  margin-bottom: 0%;

  @media (min-width: $breakpoint-to-mobile) {
    margin-bottom: initial;
  }
}
