.localization {
  gap: 24px !important;

  > div:first-child {
    width: 24px !important;
    height: 24px !important;
  }

  button {
    color: #284492 !important;
  }
}
