@import "../../css/brandColors.scss";
@import "../../css/breakpoints.scss";

$common-gap: 24px;

.ModalDeleteCardAddressBook {
  &Content {
    text-align: center;

    &Title {
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
      margin-bottom: 8px;
      letter-spacing: 0px;
      color: #000000;
    }

    &Message {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      color: #000000b8;
      max-width: 325px;
    }
  }

  &Footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $common-gap;
    margin-top: $common-gap;

    &Btn {
      background-color: map-get($brandColors, "lightestBlue") !important;
      color: map-get($brandColors, "mainBlue") !important;
      border-radius: 8px !important;
      padding: 14px 32px !important;
      font-size: 12px !important;
      font-weight: 600 !important;
      line-height: 16px !important;
      letter-spacing: 0px !important;
    }

    &ConfirmBtn {
      background-color: map-get($brandColors, "mainBlue") !important;
      color: #ffffff !important;
    }
  }
}
