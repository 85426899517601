.page {
  display: flex;
  justify-content: center;
}

.container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;

  @media (max-width: 991px) {
    margin-top: 16px;
  }
}

.card {
  max-width: 796px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 40px 40px 40px !important;
  width: 100%;

  // @media (max-width: 991px) {
  //   padding: 20px !important;
  // }
}

.needHelpText {
  margin-bottom: 0 !important;
}

.clickHereLink {
  padding-left: 16px;
  line-height: 19px;

  @media (max-width: 991px) {
    display: block;
    text-align: center;
    margin-top: 6px;
    padding-left: 0;
  }
}

.link {
  color: currentColor;
  // text-decoration: none;
  padding-left: 8px;
}
