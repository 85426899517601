.EmptyListAddressBook {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;

  &Text {
    text-align: center;
    margin-bottom: 20px;
  }
}
