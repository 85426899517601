.estimated-delivery-price-card {
  border-radius: 16px;
  background-color: #f6f6f9;
  padding: 14px 21px;
  display: flex;

  &__section {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    &--separator {
      width: 1px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.08);
      margin: 0 6px;
    }

    &__title {
      color: rgba(0, 0, 0, 0.56);
      font-size: 12px;
      font-weight: 400;
      line-height: 1.2;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;

      &__icon {
        cursor: default !important;
        position: absolute;
        right: 0;
        top: -9px;
        color: #0000008f !important;
      }
    }

    &__value {
      color: rgba(0, 0, 0, 0.87);
      font-size: 18px;
      font-weight: 400;
      line-height: 1.2;
      text-align: center;
    }
  }
}
