@import "../../../../css/brandColors.scss";

.submitBtn {
  padding-left: 90px !important;
  padding-right: 90px !important;
  margin-top: 24px;
}

.link {
  color: #{map-get($brandColors, error)} !important;
}
