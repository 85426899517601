@import "../../css/brandColors.scss";
@import "./_Parcels.variables.scss";

.moneyInput {
  display: flex !important;
  flex-direction: column;
  min-width: auto;
  // width: 100% !important;

  // @media (min-width: $media-min-width2) {
  //   width: auto !important;
  // }

  // @media (min-width: #{$media-min-width}) {
  //   min-width: 100px;
  // }

  label {
    div {
      background-color: map-get($brandColors, "lightGray-100");
    }
  }
}
