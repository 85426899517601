.price-card {
  &__logo-provider {
    display: flex;
    margin: 32px auto 0 auto;
  }

  &__service-name {
    color: rgba(0, 0, 0, 0.44);
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    text-align: center;
  }

  &__price-title {
    margin-top: 24px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    color: #000000;
  }

  &__price {
    margin-top: 4px;
    margin-bottom: 24px;
    text-align: center;
    font-size: 46px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
  }

  &__estimated {
    margin-top: auto;
  }

  &__btn {
    margin: 32px auto 0 auto;
  }
}
