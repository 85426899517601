@import "../../../../../../../../css//brandColors.scss";

.card {
  padding: 24px 40px;

  @media (max-width: 991px) {
    padding: 20px;
  }
}

.pencilIcon {
  fill: map-get($brandColors, lightBlueColor) !important;
}

.TotalChargesMessage {
  display: flex;
  margin-top: 10px;
  color: map-get($brandColors, mainBlue);

  &Text {
    margin-left: 10px;
    line-height: 1.2;
  }
}

.messageProhibitedItemsOrderDataCard {
  margin-left: auto;
}
