@font-face {
  font-family: NotoSansHebrew-SemiBold;
  src: url(../../assets/fonts-mont/NotoSansHebrew-SemiBold.ttf);
}

@font-face {
  font-family: NotoSansHebrew-Bold;
  src: url(../../assets/fonts-mont/NotoSansHebrew-Bold.ttf);
}

.top-section-homepage {
  position: relative;
  min-height: calc(100vh - 72px);
  padding-bottom: 0px;
  display: flex;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    width: 55%;
    background-color: #D1D6E4;
    margin: auto;
    /* display: none; */
  }
}

@media (min-width: 600px) {
  .top-section-homepage {
    padding-bottom: 64px;

    &::after {
      display: block;
    }
  }
}