@import "./GetBestPricePopup.variables.scss";

// @font-face {
//   font-family: Mont-Bold;
//   src: url(../../assets//fonts-mont/Mont-Bold.woff2);
// }

// @font-face {
//   font-family: Mont-SemiBold;
//   src: url(../../assets/fonts-mont/Mont-SemiBold.woff2);
// }

$side-padding-input: 18px;

.content {
  flex: 1 1 0;

  @media (min-width: $media-min-width) {
    max-width: 340px;
  }
}

.title {
  color: #112356;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: NotoSansHebrew-Bold;
  font-size: 32px;
  line-height: 125%;
  font-style: normal;
  min-width: initial;
  text-align: center;

  @media (min-width: $media-min-width) {
    text-align: initial;
    font-size: 36px;
    line-height: 111.111%;
  }
}

.subTitle {
  color: rgba(17, 35, 86, 0.71);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  margin-top: 12px;
  max-width: 308px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $media-min-width) {
    text-align: initial;
    max-width: initial;
    margin-left: initial;
    margin-right: initial;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: #f3f4f9;
  max-width: 308px;
  padding: 24px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $media-min-width) {
    margin-top: 24px;
    margin-left: initial;
    margin-right: initial;
  }
}

.input {
  label {
    > div {
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 2px 1px 0px rgba(17, 35, 86, 0.25);
      padding: 12px $side-padding-input;
    }

    ::after,
    ::before {
      display: none;
      border-bottom: 0 !important;
    }

    label {
      color: rgba(0, 0, 0, 0.44);
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      // top: -21px;

      // &[data-shrink="true"] {
      //   top: -5px;
      // }
    }
  }

  > div {
    padding: 0 $side-padding-input;
  }
}

.submitBtn {
  margin: 4px auto 0 auto;
}

.submitBtnRtl {
  svg {
    transform: rotate(180deg);
  }
}

.footer {
  color: rgba(17, 35, 86, 0.71);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-align: center;
  margin-top: 24px;
}
