@import "../../css/breakpoints.scss";

body::before {
  content: "mobile";
  display: none; /* Prevent from displaying. */
}

@include breakpoint("desktop2k") {
  body::before {
    content: "desktop2k";
  }
}

@include breakpoint("desktop") {
  body::before {
    content: "desktop";
  }
}

@include breakpoint("tablet") {
  body::before {
    content: "tablet";
  }
}

@include breakpoint("mobile") {
  body::before {
    content: "mobile";
  }
}
