@import "../../../../../../../../../../../css/brandColors.scss";

.addBtn {
  color: #{map-get($brandColors, lightBlueColor)} !important;
}

.couponInput {
  margin-top: 32px;
  margin-bottom: 16px;

  & input,
  div {
    text-align: center !important;
  }
}
