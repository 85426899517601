.card {
  margin-top: 24px;
}

.btnBack {
  margin-bottom: 34px;
}

.newShipmentBtn {
  margin-top: 66px;
  margin-left: auto;
}
