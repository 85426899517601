@import "../../css/breakpoints.scss";
@import "../../components/TheHeader/TheHeaderExportVariables.scss";

.layoutWithLeftMenu {
  display: flex;
  padding-top: 40px;
  column-gap: 60px;

  @include breakpoint("mobile") {
    padding-top: 24px;
  }

  @include breakpoint("tablet") {
    column-gap: 24px;
  }

  &WrapMenu {
    display: none;

    @media (min-width: $media-min-width-menu) {
      display: initial;
    }
  }

  &ContentPage {
    width: 100%;
    overflow: hidden;

    &Top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 16px;
      gap: 32px 24px;

      @media (min-width: $media-min-width-menu) {
        margin-bottom: 32px;
      }

      &Title {
        font-size: 24px;
        font-weight: 700;
        line-height: 32.68px;
        letter-spacing: 0em;

        @media (min-width: $media-min-width-menu) {
          font-size: 35px;
          line-height: 50px;
        }
      }

      &Search {
        order: initial;

        @include breakpoint("mobile") {
          order: 3;
        }
      }
    }
  }
}
