// Семантические цвета

// Ширины

$border-radius-lg: 16px;
$field-hint-error-message-min-height: 20px;

// === Field START ===
// === Field END ===

// Базовая типографика

:export {
  borderRadiusLg: $border-radius-lg;
}
