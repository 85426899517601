@import "../../../css/brandColors.scss";
@import "../../../css/breakpoints.scss";

.signUp {
  &.root {
    &::before {
      background: #{map-get($brandColors, mainBlue)};
    }

    &::after {
      background: #{map-get($brandColors, whiteColor)};
    }
  }

  & .formWrapper {
    transform: translateX(0);
    border-radius: 24px 0px 0px 24px;
  }

  & .infoBlock {
    transform: translateX(0);
    border-radius: 0px 24px 24px 0px;
  }
}

.signIn {
  &.root {
    &::before {
      background: #{map-get($brandColors, whiteColor)};

      @include breakpoint("tablet") {
        background: #{map-get($brandColors, mainBlue)};
      }
    }

    &::after {
      background: #{map-get($brandColors, mainBlue)};
    }
  }

  & .formWrapper {
    transform: translateX(100%);
    border-radius: 0px 24px 24px 0px;
  }

  & .infoBlock {
    transform: translateX(-100%);
    border-radius: 24px 0px 0px 24px;
  }
}

.transitionContainer {
  width: 100%;
  height: 100%;
}

.formWrapper {
  // transition: all 0.8s ease-out;
  z-index: 2222 !important;

  @include breakpoint("tablet") {
    transform: translateX(0) !important;
  }
}

.infoBlock {
  // transition: all 0.8s ease-out;
  z-index: 22;

  @include breakpoint("tablet") {
    display: none !important;
  }
}

.AuthTransitionEnterActive {
  opacity: 1;
}

.AuthTransitionExitActive {
  opacity: 0;
}
