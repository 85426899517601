@import "./TheHeaderExportVariables.scss";

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $gap-common;
  width: 100%;
}

.links {
  display: flex;
  gap: 30px;
  margin-left: 30px - $gap-common;

  &Item {
    cursor: pointer;
    font-weight: 600;
    color: #000;
    display: block;
    text-decoration: none;
  }
}
