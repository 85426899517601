.summaryCard {
  margin-bottom: 24px;
}

.insuranceCard {
  margin-bottom: 24px;

  @media (max-width: 991px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.switch {
  display: flex;
  justify-content: space-between;
  font-size: 16px !important;
}

.infoIcon {
  margin-right: 10px;
}

.auxiliaryServiceMessageInsurance {
  cursor: pointer;
  text-decoration: underline;
  margin-top: 4px;
}
