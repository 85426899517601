.fieldLabel {
  margin-top: 24px;
}

.submitBtn {
  margin-left: auto;
  margin-top: 16px;

  // @media (max-width: 991px) {
  //   margin-right: auto;
  // }
}

.formType {
  @media (max-width: 991px) {
    margin: 24px 0 4px 0;
  }
}

.input {
  @media (max-width: 991px) {
    margin-bottom: 20px !important;
  }
}
