@import "../../css/breakpoints.scss";
@import "../../css/brandColors.scss";

.card {
  display: flex;
  align-items: center;
  max-height: 64px;
  position: relative;
  max-width: 380px;
  width: 100%;

  @include breakpoint("tablet") {
    justify-content: space-between;
    max-width: 100%;
  }

  &:nth-of-type(2) {
    margin-left: 16px;
    margin-right: 16px;

    @include breakpoint("tablet") {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
