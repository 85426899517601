@import "../../css/variables.scss";
@import "../../css/breakpoints.scss";

:root {
  --gap-list-address-book: 24px;

  @include breakpoint("mobile") {
    --gap-list-address-book: 16px;
  }
}

.ListAddressBook {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-list-address-book);

  &Pagination {
    margin-top: var(--gap-list-address-book);
  }
}
.NavigationAddressBook {
  display: flex;
  gap: 20px;
  margin: 16px 0 25px;
  -webkit-font-smoothing: antialiased;
  button {
    background: none;
    border: none;
    padding: 5px 0;
    color: #284492;
    cursor: pointer;
    font-weight: 700;
    &.Active {
      border-bottom: 2px solid #284492;
    }
  }

}
