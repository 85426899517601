.modal {
  width: calc(100% - 32px) !important;
  padding: 60px 16px 40px 16px !important;

  @media (min-width: 600px) {
    width: 75% !important;
    max-width: 400px !important;
    padding: 60px 40px 40px 40px !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.title {
  margin-top: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  color: #112356;
}

.message {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-family: Open Sans;
  letter-spacing: 0px;
  text-align: center;
}

.updateBtn {
  margin-top: 32px;
}
