@font-face {
  font-family: Mont-Bold;
  src: url(../../../../../../assets//fonts-mont/Mont-Bold.woff2);
}

// @font-face {
//   font-family: Mont-SemiBold;
//   src: url(../../../../../../assets/fonts-mont/Mont-SemiBold.woff2);
// }

// @font-face {
//   font-family: NotoSansHebrew-SemiBold;
//   src: url(../../../../../../assets/fonts-mont/NotoSansHebrew-SemiBold.ttf);
// }

// @font-face {
//   font-family: NotoSansHebrew-Bold;
//   src: url(../../../../../../assets/fonts-mont/NotoSansHebrew-Bold.ttf);
// }

$media-min-width-mobile: 600px;

// .rootModal {
//   z-index: 1200 !important;
//   margin-top: 72px;

//   @media (min-width: $media-min-width-mobile) {
//     z-index: 1300 !important;;
//     margin-top: initial;
//   }
// }

.modal {
  border-radius: 0px !important;
  background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF !important;
  width: 100% !important;
  max-height: 100% !important;
  padding: 70px 10px 40px 24px !important;

  @media (min-width: $media-min-width-mobile) {
    border-radius: 32px !important;
    width: calc(100% - 32px) !important;
    max-width: 800px !important;
    padding: 40px 20px 40px 40px !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 331px;
  margin: auto;
  padding-right: 10px !important;

  @media (min-width: $media-min-width-mobile) { 
    max-width: initial;
    margin: initial;
    padding-right: 20px !important;
  }
}

.FlashingLight {
  width: 40px;
  height: 40px;

  @media (min-width: $media-min-width-mobile) {
    width: 48px;
    height: 48px;
  }
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px 0;

  @media (min-width: $media-min-width-mobile) { 
    flex-direction: row;
  }

  &>* {
    flex: 1 1 0;
  }
}

.titleSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  
  @media (min-width: $media-min-width-mobile) { 
    align-items: initial;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 6px;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Mont-Bold, sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px; /* 125% */

  @media (min-width: $media-min-width-mobile) {
    gap: 8px;
    font-feature-settings: 'liga' off, 'clig' off;
    // font-family: Mont-SemiBold, sans-serif;
    // font-family: NotoSansHebrew-SemiBold, sans-serif;
    // font-family: NotoSansHebrew-Bold, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 40px; /* 111.111% */
  }
}

.subtitle {
  color: rgba(17, 35, 86, 0.71);
  color: rgba(17, 35, 86, 0.71);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  text-align: center;

  @media (min-width: $media-min-width-mobile) { 
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 32px */
    text-align: center;
    text-align: initial;
  }
}

.banner {
  display: flex;
  justify-content: center;
  order: -1;
  
  @media (min-width: $media-min-width-mobile) { 
    order: 2;
  }

  img {
    width: 232px;
    height: 193px;
    flex-shrink: 0;

  }
}

.text {
  color: #566387;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */

  @media (min-width: $media-min-width-mobile) { 
    color: #566387;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 24px */
  }
}

.importantMessages {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px 32px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $media-min-width-mobile) { 
    flex-direction: row;
  }

  &>* {
    flex: 1 1 0;
  }
}

.IndexFingerUp {
  display: flex;
  align-items: center;
  align-items: flex-start;
  gap: 16px;

  img {
    width: 56px;
    height: 56px;
  }
}

.updateBtn {
  margin: 40px auto 0 auto;
}
