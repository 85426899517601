.MetricSystemBtns {
  display: flex;
  height: 28px;
  border-radius: 99999px;
  width: 207px;
  margin: auto;
  justify-content: center;
  overflow: hidden;
  border: 1px solid rgba(127, 144, 177, 0.3);

  &Btn {
    flex: 1 1 0;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // gap: 8px;
  }

  &BtnLabel {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
  }

  // &BtnIcon {
  //   color: #4AB6BD !important;
  // }

  &BtnActive {
    background: rgba(127, 144, 177, 0.3);
  }

  &Separator {
    width: 1px;
    height: 100%;
    background: rgba(127, 144, 177, 0.6);
  }
}
